import Pusher from "pusher-js";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      isAdmin: "auth/isAdmin",
      isUser: "auth/isUser",
      isResellerUser: "auth/isResellerUser",
    }),
  },

  methods: {
    connectPusher() {
      if (this.isEmptyObj(this.user)) {
        return false;
      }

      let name = this.isAdmin ? this.$pusherAdminChannel : this.user.customerId;

      const pusher = new Pusher(this.$pusherData.key, {
        cluster: this.$pusherData.cluster,
      });

      window.channel = pusher.subscribe(`channel-${name}`);
      window.channel.unbind();
      // notification / 'group' can be ('all', 'admin-user', 'admin', 'all-user', 'user', 'reseller-user')
      window.channel.callbacks.removeCallback(["_notification"]);
      window.channel.bind("notification", (data) => {
        if (this.isEmptyObj(data)) {
          return false;
        }

        if (data.group === "all") {
          this.showNotification(this.$t(data.messageKey, data.keys), data.type);

          return true;
        }

        if (data.group === "admin-user" && (this.isAdmin || this.isUser)) {
          this.showNotification(this.$t(data.messageKey, data.keys), data.type);

          return true;
        }

        if (
          (data.group === "admin" && this.isAdmin) ||
          (data.group === "all-user" && !this.isAdmin) ||
          (data.group === "user" && this.isUser) ||
          (data.group === "reseller-user" && this.isResellerUser)
        ) {
          this.showNotification(this.$t(data.messageKey, data.keys), data.type);

          return true;
        }
      });
    },
  },

  created() {
    this.connectPusher();
  },
};
