const GlobalVariables = {
  install(Vue) {
    // app host
    Vue.prototype.$appHost = process.env.VUE_APP_APP_HOST;
    // app log level
    Vue.prototype.$debug = process.env.VUE_APP_LOG_LEVEL !== "error";
    // cloud host
    Vue.prototype.$cloudServerHost = process.env.VUE_APP_CLOUDSERVER_HOST;
    // currency
    Vue.prototype.$currencies = process.env.VUE_APP_CURRENCIES;
    // tax
    Vue.prototype.$tax = process.env.VUE_APP_TAX;
    // locales
    Vue.prototype.$locales = process.env.VUE_APP_APP_LOCALES.split("|");
    // roles
    Vue.prototype.$roles = {
      admin: process.env.VUE_APP_ROLE_ADMIN,
      reselleruser: process.env.VUE_APP_ROLE_RESELLER_USER,
      previousAdmin: process.env.VUE_APP_ROLE_PREVIOUS_ADMIN,
      user: process.env.VUE_APP_ROLE_USER,
      allowedToSwitch: process.env.VUE_APP_ROLE_ALLOWED_TO_SWITCH,
    };
    // user roles
    Vue.prototype.$userRoles = {
      none: "all",
      admin: process.env.VUE_APP_ROLE_ADMIN_C,
      tech: process.env.VUE_APP_ROLE_TECH_C,
      billing: process.env.VUE_APP_ROLE_BILLING_C,
    };
    // types of numbers
    Vue.prototype.$typesNumber = [
      process.env.VUE_APP_TYPE_NUMBER_FAX,
      process.env.VUE_APP_TYPE_NUMBER_MOB,
      process.env.VUE_APP_TYPE_NUMBER_LANDLINE,
    ];
    // qrcode
    Vue.prototype.$qrcode = {
      width: parseInt(process.env.VUE_APP_QRCODE_WIDTH),
      height: parseInt(process.env.VUE_APP_QRCODE_HEIGHT),
    };
    // pagination
    Vue.prototype.$perPage = parseInt(process.env.VUE_APP_PER_PAGE);
    Vue.prototype.$perPageOptions = process.env.VUE_APP_PER_PAGE_OPTIONS.split(
      ","
    ).map((item) => parseInt(item));
    // cloud
    Vue.prototype.$port = process.env.VUE_APP_CLOUD_PORT;
    Vue.prototype.$noVNCPath = process.env.VUE_APP_NOVNC_PATH;
    // notification
    Vue.prototype.$notification = {
      timeClose: parseInt(process.env.VUE_APP_NOTIFICATION_TIME_CLOSE),
      horizontalAlign: process.env.VUE_APP_NOTIFICATION_HORIZONTAL_ALIGN,
      verticalAlign: process.env.VUE_APP_NOTIFICATION_VERTICAL_ALIGN,
    };
    // show password forgotten
    Vue.prototype.$showPasswordForgotten =
      process.env.VUE_APP_SHOW_PASSWORD_FORGOTTEN;
    // spinner
    Vue.prototype.$spinner = {
      color: process.env.VUE_APP_SPINNER_COLOR,
      size: parseInt(process.env.VUE_APP_SPINNER_SIZE),
    };
    // debounce
    Vue.prototype.$debounceTime = parseInt(process.env.VUE_APP_DEBOUNCE_TIME);
    Vue.prototype.$debounceTimeJS = parseInt(
      process.env.VUE_APP_DEBOUNCE_TIME_JS
    );
    // range date
    Vue.prototype.$dateRanges = process.env.VUE_APP_DATERANGES.split(",");
    // idle
    Vue.prototype.$idleTime = parseInt(process.env.VUE_APP_IDLE_TIME);
    // firewall rule
    Vue.prototype.$ruleDirectionTypes =
      process.env.VUE_APP_RULE_DIRECTION_TYPES.split(",");
    Vue.prototype.$ruleActions = process.env.VUE_APP_RULE_ACTIONS.split(",");
    Vue.prototype.$ruleProtocols =
      process.env.VUE_APP_RULE_PROTOCOLS.split(",");
    // payment discounts
    Vue.prototype.$discounts = JSON.parse(
      process.env.VUE_APP_PAYMENT_DISCOUNTS
    );
    // payment interval
    Vue.prototype.$paymentInterval = JSON.parse(
      process.env.VUE_APP_PAYMENT_INTERVAL
    );
    // default country code
    Vue.prototype.$defaultCountryCode =
      process.env.VUE_APP_COMPANY_COUNTRY_CODE;

    // cloud resources
    Vue.prototype.$resources = {
      cloud: {
        cpu_cores_min: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_CPU_CORES_MIN
        ),
        cpu_cores_max: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_CPU_CORES_MAX
        ),
        cpu_units: parseInt(process.env.VUE_APP_CLOUDSERVER_DEFAULT_CPU_UNITS),
        disk_size_min_gb: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_DISK_SIZE_MIN_GB
        ),
        disk_size_max_gb: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_DISK_SIZE_MAX_GB
        ),
        disk2_size_min_gb: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_DISK2_SIZE_MIN_GB
        ),
        disk2_size_max_gb: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_DISK2_SIZE_MAX_GB
        ),
        memory_size_min_gb: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_MEMORY_SIZE_MIN_GB
        ),
        memory_size_max_gb: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_MEMORY_SIZE_MAX_GB
        ),
        net_speed: parseInt(process.env.VUE_APP_CLOUDSERVER_DEFAULT_NET_SPEED),
        traffic: parseInt(process.env.VUE_APP_CLOUDSERVER_DEFAULT_TRAFFIC),
        ip4addresses: parseInt(
          process.env.VUE_APP_CLOUDSERVER_USER_LIMIT_IP4ADDRESSES
        ),
      },
    };

    // network prefixes
    Vue.prototype.$bitmask = {
      v4Min: parseInt(process.env.VUE_APP_BITMASKV4_MIN),
      v4Max: parseInt(process.env.VUE_APP_BITMASKV4_MAX),
      v6Min: parseInt(process.env.VUE_APP_BITMASKV6_MIN),
      v6Max: parseInt(process.env.VUE_APP_BITMASKV6_MAX),
    };

    // pusher
    Vue.prototype.$pusherData = {
      id: process.env.VUE_APP_PUSHER_APP_ID,
      key: process.env.VUE_APP_PUSHER_KEY,
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    };

    // footer link
    Vue.prototype.$footerLink = process.env.VUE_APP_FOOTER_LINK;
    // check task is done interval
    Vue.prototype.$checkTaskInterval = process.env.VUE_APP_CHECK_TASK_INTERVAL;
    // pusher channel name for admin
    Vue.prototype.$pusherAdminChannel =
      process.env.VUE_APP_PUSHER_ADMIN_CHANNEL;
    // paypal client id
    Vue.prototype.$paypalClientId = process.env.VUE_APP_PAYPAL_CLIENT_ID;
    // paypal additional percent
    Vue.prototype.$paypalPercent = process.env.VUE_APP_PAYPAL_PERCENT;
    // number of days to check usage tables
    Vue.prototype.$usagePeriod = process.env.VUE_APP_CHECK_USAGE_PERIOD;
    // use blesta or ispworker
    Vue.prototype.$useBlesta =
      process.env.VUE_APP_USE_BLESTA === "true" ? true : false;
  },
};

export default GlobalVariables;
