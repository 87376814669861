<template>
  <div
    class="sidebar"
    data-color="silver"
    :data-background-color="backgroundColor"
    :style="sidebarStyle"
  >
    <div class="logo">
      <a :href="getLink()" class="simple-text logo-mini">
        <div class="logo-img"></div>
      </a>
      <a :href="getLink()" class="simple-text logo-normal">
        <div class="logo-text">
          {{ $t("title.ingate") }}
        </div>
        <span class="ingate-text">{{ $t("title.ingate_text") }}</span>
      </a>
      <div class="navbar-minimize">
        <md-button
          id="minimizeSidebar"
          class="md-round md-just-icon md-transparent"
          @click="minimizeSidebar"
        >
          <i
            class="material-icons text_align-center visible-on-sidebar-regular"
          >
            more_vert
          </i>
          <i
            class="material-icons design_bullet-list-67 visible-on-sidebar-mini"
          >
            view_list
          </i>
        </md-button>
      </div>
    </div>

    <div class="sidebar-wrapper mdscrollbar" ref="sidebarScrollArea">
      <slot></slot>
      <md-list class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </md-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "sidebar-component",

  props: {
    activeColor: {
      type: String,
      default: "silver",
    },

    backgroundColor: {
      type: String,
      default: "white",
      validator: (value) => {
        let acceptedValues = ["", "black", "white"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },

    logo: {
      type: String,
      default: "../img/logo.png",
    },

    sidebarLinks: {
      type: Array,
      default: () => [],
    },

    autoClose: {
      type: Boolean,
      default: true,
    },
  },

  provide() {
    return {
      autoClose: this.autoClose,
    };
  },

  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },

    getLink() {
      return `https://${this.$appHost}`;
    },
  },

  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url('../img/sidebar.webp')`,
      };
    },
  },

  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>

<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
.logo-normal .logo-text {
  letter-spacing: 12px;
  line-height: 10px;
}
.logo-normal .ingate-text {
  font-size: 7px;
}
</style>
