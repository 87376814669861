import i18n from "@/i18n";
import axios from "axios";
import swal from "sweetalert2";
import store from "../store";

// Response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    const { data } = error.response;
    let text = data.error;

    // http code 401
    if (status === 401) {
      let textError = i18n.t("error.token_invalid");

      if (text === "Expired JWT Token") {
        textError = i18n.t("error.token_expired");
      }

      swal
        .fire({
          icon: "warning",
          title: textError,
          reverseButtons: true,
          customClass: {
            confirmButton: "md-button md-success",
          },
          buttonsStyling: false,
        })
        .then(() => {
          store.commit("auth/logout");

          return false;
        });

      setTimeout(() => store.commit("auth/logout"), 5000);

      return Promise.reject(error);
    }

    if (status <= 300 || (status >= 400 && status < 500)) {
      return Promise.reject(error);
    }

    // forceError
    if (data.hasOwnProperty("forceError")) {
      return Promise.reject(data);
    }

    // http code 500 and database error
    if (
      status === 500 &&
      typeof data === "string" &&
      data.includes("database")
    ) {
      swal.fire({
        icon: "error",
        text: data,
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false,
      });

      // location.reload();

      return new Promise(() => {});
    }

    // http code 503
    if (status === 503) {
      window.location = "/maintenance";

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
