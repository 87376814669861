export default {
  methods: {
    showNotification(
      message,
      type = "success",
      timeout = null,
      ha = null,
      al = null,
      icon = null
    ) {
      this.$notify({
        timeout: timeout ? timeout : this.$notification.timeClose,
        message: message,
        icon: icon,
        horizontalAlign: ha ? ha : this.$notification.horizontalAlign,
        verticalAlign: al ? al : this.$notification.verticalAlign,
        type: type,
      });
    },
  },
};
