import swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      lang: "lang/getLang",
      hasRole: "auth/hasRole",
    }),

    getBreadcrumbsUser() {
      if (this.isEmptyObj(this.server)) {
        return [];
      }

      const type = this.server?.type?.toLowerCase();

      switch (true) {
        case type.includes("cloud"):
          return [{ name: "cloud_server_list" }];

        case type.includes("rack"):
          return [{ name: "rack_list_user" }];

        default:
          return [{ name: "dedicated_server_list" }];
      }
    },
  },

  data() {
    return {
      activeSwitchUserModal: false,
      pagination: {
        perPage: this.$perPage,
        currentPage: 1,
        perPageOptions: this.$perPageOptions,
        total: 0,
        from: 0,
        to: 0,
      },
      activeTab: 0,
      search: null,
    };
  },

  methods: {
    focusOnInput(id = 'editInput') {
      setTimeout(() => {
          const elByRef = this.$refs[id]?.$el;

          if (elByRef) {
            elByRef.focus();

            return true;
          }

          document.getElementById(id)?.focus();
        },
        500
      );
    },

    focusOnSelect(id = 'editInput') {
      setTimeout(() => {
          const el = document.querySelector(`#${id} [type=search]`);
          el?.focus();
        },
        500
      );
    },

    toLower(text) {
      return text.toString().toLowerCase();
    },

    isEmpty(val) {
      if (typeof val !== "undefined" && val !== null) {
        return !val || val.length === 0 || val === 0 || val === "0";
      }

      return true;
    },

    isEmptyObj(obj) {
      if (typeof obj !== "undefined" && obj !== null) {
        return Object.keys(obj).length === 0;
      }

      return true;
    },

    confirmDelete(fn, item) {
      if (this.isEmptyObj(item)) {
        return false;
      }

      swal
        .fire({
          title: this.$t("title.sure"),
          showCancelButton: true,
          cancelButtonText: this.$t("btn.no"),
          confirmButtonText: this.$t("btn.yes"),
        })
        .then((result) => {
          if (
            result.value &&
            !this.isEmptyObj(item) &&
            typeof fn === "string"
          ) {
            this[fn](item);
          }
        });
    },

    performAction(fn, item = null) {
      swal
        .fire({
          title: this.$t("swal.perform_action"),
          showCancelButton: true,
          cancelButtonText: this.$t("btn.no"),
          confirmButtonText: this.$t("btn.yes"),
        })
        .then((result) => {
          if (result.value && typeof fn === "string") {
            this[fn](item);
          }
        });
    },

    getActivePanel(index) {
      this.activeTab = index;
    },

    updateEditingField(value, obj, key, index = null) {
      if (index !== null) {
        return (this[obj][index][key] = value);
      }

      return (this[obj][key] = value);
    },

    checkCustomerId() {
      if (typeof this.customerId !== "undefined" && this.customerId > 0) {
        return true;
      }

      return false;
    },

    validateEmail(email) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },

    getLength(obj) {
      if (typeof obj === "object") {
        return Object.keys(obj).length;
      }

      return obj.length;
    },

    capitalize(str) {
      if (typeof str === "string" && str.length > 0) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }

      return "";
    },

    toFix(val, dec = 2) {
      if (isNaN(val) || val === null) {
        return 0;
      }

      // round
      return (Math.round((+val + 0.0001) * 100) / 100).toFixed(dec);
    },

    roundUp(number, dec = 2) {
      return Math.ceil(number * Math.pow(10, dec)) / Math.pow(10, dec);
    },

    roundDown(number, dec = 2) {
      return Math.floor(number * Math.pow(10, dec)) / Math.pow(10, dec);
    },

    copyText(pass, text = "") {
      if (!pass) {
        return false;
      }

      navigator.clipboard.writeText(pass).then(() => {
        if (text) {
          this.showNotification(text, "success");
        }
      });
    },

    checkAccessDenied(error) {
      if (!this.isEmpty(error.code) && error.code === 403) {
        return true;
      }

      return false;
    },

    inetAton(ip) {
      // split into octets
      let a = ip.split(".");
      let buffer = new ArrayBuffer(4);
      let dv = new DataView(buffer);

      for (let i = 0; i < 4; i++) {
        dv.setUint8(i, a[i]);
      }

      return dv.getUint32(0);
    },

    getFistElementOfObject(obj) {
      if (typeof obj === "object") {
        return obj[Object.keys(obj)[0]];
      }

      return null;
    },

    checkPermission(additionalRole = null) {
      if (this.hasRole(this.$userRoles.billing)) {
        return false;
      }

      if (additionalRole && this.hasRole(additionalRole)) {
        return false;
      }

      return true;
    },

    showErrorMessage({ data }) {
      if (!this.isEmpty(data.message)) {
        swal.fire({
          icon: "error",
          text: data.message,
          customClass: {
            confirmButton: "md-button md-success",
          },
          buttonsStyling: false,
        });

        return false;
      }

      swal.fire({
        icon: "error",
        text: this.$t("error.general"),
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false,
      });
    },

    showErrorNotification({ data }) {
      if (!this.isEmpty(data?.message)) {
        this.showNotification(data.message, "danger");

        return false;
      }

      if (!this.isEmpty(data?.messageKey)) {
        this.showNotification(this.$t(data.messageKey), "warning");

        return false;
      }

      if (!this.isEmpty(data?.error)) {
        this.showNotification(data.error, "danger");

        return false;
      }

      this.showNotification(this.$t("error.general"), "danger");
    },

    showGeneralErrorMessage() {
      swal.fire({
        icon: "error",
        text: this.$t("error.general"),
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false,
      });
    },

    getCurrencyPrefix(currency = "") {
      switch (currency.toLowerCase()) {
        case "eur":
          return "€";

        case "usd":
          return "$";

        default:
          return "€";
      }
    },
  },
};
