<template>
  <div
    :class="[
      { open: isOpen },
      { dropdown: direction === 'down' },
      { dropup: direction === 'up' },
    ]"
    @click="toggleDropDown"
    @keydown.down="isOpen = true"
    v-click-outside="closeDropDown"
  >
    <slot name="title">
      <a
        class="dropdown-toggle"
        data-toggle="dropdown"
        href="javascript:void(0)"
      >
        <i :class="icon"></i>
        <p class="notification">
          {{ title }}
          <b class="caret"></b>
        </p>
      </a>
    </slot>
    <div :style="getStyle()">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "drop-down",

  props: {
    direction: {
      type: String,
      default: "down",
    },
    multiLevel: {
      type: Boolean,
      default: false,
    },
    title: String,
    icon: String,
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    highlightedItem() {
      return this.highlightItems[this.highlightIndex];
    },
  },

  data() {
    return {
      highlightIndex: -1,
      highlightItems: [],
      position: {},
      isOpen: false,
    };
  },

  methods: {
    setPosition() {
      this.position = this.$el.querySelector("button").getBoundingClientRect();
    },

    getStyle() {
      if (this.appendToBody) {
        const { top, left, width, height } = this.position;

        return `z-index: 1111; position: fixed; top: ${top + height}px;
          left: ${left - (200 - width)}px;`;
      }

      return "";
    },

    toggleDropDown() {
      this.isOpen = this.multiLevel ? true : !this.isOpen;
    },

    closeDropDown() {
      this.isOpen = false;
    },

    createKeydownListener() {
      window.addEventListener("keydown", this.keyNavigation);
    },

    createScrollListener() {
      window.addEventListener("scroll", this.setPosition);
    },

    destroyKeyDownListener() {
      window.removeEventListener("keydown", this.keyNavigation);
    },

    destroyScrollListener() {
      window.addEventListener("scroll", this.setPosition);
    },

    destroyListeners() {
      this.destroyKeyDownListener();

      if (this.appendToBody) {
        this.destroyScrollListener();
      }
    },

    keyNavigation(event) {
      switch (event.key) {
        case "ArrowUp":
          event.preventDefault();
          this.setHighlight("up");
          break;

        case "ArrowDown":
          event.preventDefault();
          this.setHighlight("down");
          break;

        case "Enter":
          this.isOpen = false;
          this.destroyListeners();
          this.setSelection();
          break;

        case "Escape":
        case "Tab":
          this.isOpen = false;
          this.destroyListeners();
      }
    },

    setSelection() {
      if (this.highlightedItem) {
        if (this.highlightedItem.querySelector("a")) {
          return this.highlightedItem.querySelector("a").click();
        }

        this.highlightedItem.click();
      }
    },

    setHighlightItems() {
      if (this.$el.querySelectorAll) {
        this.highlightItems = Array.from(this.$el.querySelectorAll("ul li"));
      }
    },

    setHighlight(direction) {
      this.setHighlightItems();

      if (this.highlightItems.length) {
        if (direction === "down") {
          if (this.highlightIndex === this.highlightItems.length - 1) {
            this.highlightIndex = 0;
          } else {
            this.highlightIndex++;
          }
        } else {
          if (this.highlightIndex === 0) {
            this.highlightIndex = this.highlightItems.length - 1;
          } else {
            this.highlightIndex--;
          }
        }

        this.clearAllHighlights();
        this.setItemHighlight();
      }
    },

    clearAllHighlights() {
      this.highlightItems.forEach((item) => {
        item.classList.remove("active");
      });
    },

    setItemHighlight() {
      if (this.highlightedItem) {
        this.highlightedItem.classList.add("active");
      }
    },
  },

  mounted() {
    this.setHighlightItems();
  },

  beforeDestroy() {
    this.highlightIndex = -1;
    this.highlightItems = [];
    this.clearAllHighlights();
    this.destroyListeners();
  },

  watch: {
    isOpen() {
      if (this.isOpen) {
        this.position = this.$el
          .querySelector("button")
          .getBoundingClientRect();

        if (this.appendToBody) {
          this.createScrollListener();
        }

        return this.createKeydownListener();
      }

      this.highlightIndex = -1;
      this.clearAllHighlights();
    },
  },
};
</script>
