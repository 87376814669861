<template>
  <ol class="breadcrumbs">
    <li>
      <router-link :to="{ name: `dashboard_${isAdmin ? 'admin' : 'user'}` }">
        <md-icon>dashboard</md-icon>
        {{ $t("page.home") }}
      </router-link>
    </li>

    <template v-if="!isEmptyObj(links)">
      <li v-for="(link, index) in links" :key="index">
        <span class="separate">/</span>
        <router-link :to="{ name: link.name, params: link.params }">
          {{ $t(`page.${link.name}`) }}
        </router-link>
      </li>
    </template>

    <li v-if="!isEmptyObj($route)">
      <span class="separate">/</span>
      <span class="currentPage">
        {{ $t(`page.${$route.name}`, { id: prmValue }) }}
      </span>
    </li>
  </ol>
</template>

<script>
import helpers from "@/mixins/helpers";
import { mapGetters } from "vuex";

export default {
  name: "bread-crumbs",

  mixins: [helpers],

  props: {
    prmValue: {
      type: [Number, String],
      default: "",
    },
    links: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
    }),
  },
};
</script>
