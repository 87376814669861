export default {
  mounted() {
    document.addEventListener("copy", (event) => {
      if (event == null || event.srcElement == null) {
        return;
      }

      const selection = document.getSelection();

      if (selection.toString().trim()) {
        event.clipboardData.setData("text/plain", selection.toString().trim());
        event.preventDefault();
      }
    });
  },
};
